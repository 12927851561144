import { Grid } from "@mui/material";
import React from "react";
import Logo from "../atoms/Logo";

function Logos(props) {
  return (
    <div className="block mx-auto my-4 w-full text-black">
      <h3 className="p-3 font-bold leading-10 text-center uppercase text-ligthGrey">{props.title}</h3>

      <Grid container spacing={2} className="justify-center justify-items-center w-full">
        {props.logos.map((logo) => (
          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            className="flex justify-center justify-items-center backgroundColor"
            sx={{ minHeight: "64px" }}
            key={logo.alt}
          >
            <Logo logo={logo} index={logo.alt} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Logos;
